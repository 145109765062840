import React, { useEffect, useState } from "react";
import { Button, message, Result, Spin } from "antd";
import { useLocation } from "react-router-dom";
import { apiGetToken } from "../../services/CommonServices";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export const AuthGuard = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const tokenStorage = JSON.parse(localStorage.getItem("auth"))?.access_token;
  const [isAuthenticated, setIsAuthenticated] = useState(!!tokenStorage);
  const location = useLocation();
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(location.search);
  const params = {
    customer_id: searchParams.get("customer_id"),
    mall_id: searchParams.get("mall_id"),
    timestamp: searchParams.get("timestamp"),
    intent: searchParams.get("intent"),
    sign:
      searchParams.get("sign") ??
      "aRNDNUoilUiRoeLM19Hq+OSYqs8veK+Ad9z3PEt6Z2qW1kzq3NO12oYr7MPn6eg5Fq48cGu0gqMYsM50N1dXl+fdBpdGp/Onnna5MXmMUeR4SVz1eLX+aKbmxp+iCVbD2aDyZpUq66Ab2vSjE4aItsmA58ttkIy1O0EvD0eo3kMZIW3e/Z1bLexLPThQOAjbp6HRQWZLcu3lrwul7hW3xX+GwHPPyzaQls9MwzSDFtbWXDEDsHKVAeGUxPsba9ksWjrU8p9P5RewG7KYdbSriiToJEXmmY3kmisaFpMh+NWhkPyxdheA6FPZxiRQ/P/ObcGsN73QQSCTeqfG/QoBgQ==",
  };

  const fetchToken = async () => {
    if (params?.customer_id) {
      try {
        setLoading(true);
        const res = await apiGetToken(params);
        localStorage.setItem("auth", JSON.stringify(res.data));
        setIsAuthenticated(true);
      } catch (e) {
        console.error(e);
        message.error(t("authen_failed"));
        localStorage.clear();
      } finally {
        setLoading(false);
      }
    } else {
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    if (!isAuthenticated || params?.customer_id) {
      fetchToken();
    }
  }, [location, isAuthenticated, params?.customer_id]);

  if (loading) {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ color: "#fff" }} spin />}
        fullscreen
      />
    );
  }

  if (!isAuthenticated) {
    return (
      <Result
        status="403"
        title="403"
        subTitle={t("unauthorized")}
        extra={
          <Button
            type="primary"
            style={{ backgroundColor: "#B20070" }}
            onClick={() => {
              window.open("https://evercharge.vn/");
            }}
          >
            {t("back")}
          </Button>
        }
      />
    );
  }

  return <>{children}</>;
};
