import { message } from "antd";
import axios from "axios";

const unauthorizedCode = [401];

const HttpService = axios.create({
  timeout: 60000,
  baseURL: process.env.REACT_APP_URL_API,
});

HttpService.interceptors.request.use(
  (config) => {
    config.startTime = Date.now();
    const token = JSON.parse(localStorage.getItem("auth"))?.access_token;
    if (!config?.public && token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

HttpService.interceptors.response.use(
  ({ data }) => data,
  (error) => {
    const { response } = error;
    if (response && unauthorizedCode.includes(response.status)) {
      message.error("Phiên đăng nhập hết hạn, vui lòng đăng nhập lại!");
      localStorage.clear();
    }
    return Promise.reject(error);
  }
);

export default HttpService;
